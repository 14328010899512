<template>
  <BaseWindow
    @closeWindow="closeWindow"
    :stopClickOutside="true"
    :show="true"
    :showCloseButton="true"
    :titleText="$t('general.leaders')"
    :globalClassName="'leader-board-outer'"
  >
    <div class="leader-board">
      <div class="leader-board-top">
        <template v-if="topThree.length > 2">
          <TopPlayer
            class="player-1"
            playerNo="2"
            :player="topThree[1]"
            @click.native="showPlayerDetails(topThree[1])"
          />
          <TopPlayer
            class="player-2"
            playerNo="1"
            :player="topThree[0]"
            @click.native="showPlayerDetails(topThree[0])"
          />
          <TopPlayer
            class="player-3"
            playerNo="3"
            :player="topThree[2]"
            @click.native="showPlayerDetails(topThree[2])"
          />
        </template>
      </div>
      <div class="leader-board-players">
        <LeaderPlayer
          v-for="(player, index) in otherTops"
          :leaderBoardIndex="index + 4"
          :key="index"
          :playerIndex="index % 2"
          :player="player"
          @click.native="showPlayerDetails(player)"
          :me="true"
        />
      </div>
      <div class="leader-board-bottom">
        <LeaderPlayer :me="false" :player="playerMe" />
      </div>
    </div>
  </BaseWindow>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LeaderBoard",
  components: {
    BaseWindow: () => import("../BaseWindow"),
    Avatar: () => import("@/components/Misc/Avatar"),
    TopPlayer: () => import("./components/TopPlayer"),
    LeaderPlayer: () => import("./components/LeaderPlayer"),
  },
  watch: {
    "$store.state.modals.leaderBoard": {
      handler(val) {
        if (val) {
          this.$store.dispatch("top50/getTop50Players");
          this.$adjust.trackEvent("LeadersWindowOn");
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      getTop50: "top50/getTop50",
    }),
    topThree() {
      if (this.getTop50 && this.getTop50.length > 2) {
        return this.getTop50.filter((player, index) => index < 3);
      }
      return [];
    },
    otherTops() {
      if (this.getTop50 && this.getTop50.length > 3) {
        return this.getTop50.filter((player, index) => index > 2);
      }
      return [];
    },
    playerMe() {
      return {
        name: this.$store.state.user.name,
        chip: this.$store.state.user.chip,
        avatar: this.$store.state.user.avatar,
        country: this.$store.state.user.country,
        placement: this.$store.state.user.placementInPlayers,
      };
    },
  },
  methods: {
    closeWindow() {
      this.$store.commit("modals/hide", "leaderBoard");
    },
    async showPlayerDetails(player) {
      if (player.id) {
        if (parseInt(player.id) === this.$store.state.user.id) {
          return this.$plugins.toast.show({
            text: this.$t("searchWindow.ownId"),
            duration: 2000,
            variant: "danger",
          });
        }
        const res = await this.$store.dispatch("friends/getProfiles", [
          player.id,
        ]);
        if (!res) {
          this.$plugins.toast.show({
            text: this.$t("confirmWindow.userNotFound"),
            duration: 2000,
            variant: "danger",
          });
          return;
        }
        this.$store.commit("friends/setSelectedUserModal", res[0]);
        this.$store.commit("modals/show", "friendDetails");
      } else {
        return this.$plugins.toast.show({
          text: this.$t("searchWindow.validId"),
          duration: 2000,
          variant: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.leader-board {
  width: 100%;
  height: 92%;
  background: url("~@/assets/images/leaderboard/leader-board-bg.png") no-repeat
      50% 50%,
    transparent;
  background-size: contain;
  display: flex;
  flex-direction: column;
  &-top {
    height: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .top-player {
      width: 25%;
      display: flex;
      justify-content: center;
      z-index: 2;
      cursor: pointer;
    }
  }
  &-players {
    will-change: scroll;
    height: 61%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
  }
  &-bottom {
    height: 9%;
    background: rgb(59, 11, 20);
    background: linear-gradient(
      0deg,
      rgba(59, 11, 20, 1) 0%,
      rgba(150, 5, 24, 1) 100%
    );
    border-top: solid 5px #d80b28;
    display: flex;
    justify-content: center;
  }
}
</style>
